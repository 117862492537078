export const EMPTY_DICT = {}
export const EMPTY_ARRAY = []

export const UserPrivilegeLevels = Object.freeze({
  BASIC: 'BASIC',
  BASIC_IPAD: 'BASIC_IPAD',
  FLOORPLANEDITOR: 'FLOORPLANEDITOR',
  SUBMANAGER: 'SUBMANAGER',
  MANAGER: 'MANAGER',
  USERMANAGER: 'USERMANAGER',
  SUPERUSER: 'SUPERUSER',
})

export const GroupUserPrivilegeLevels = Object.freeze({
  CORPORATE_DIRECTOR_CEO: 'CORPORATE_DIRECTOR_CEO',
  GENERAL_MANAGER_ADVANCED: 'GENERAL_MANAGER_ADVANCED',
  GENERAL_MANAGER_STANDARD: 'GENERAL_MANAGER_STANDARD',
  MARKETING_MANAGER: 'MARKETING_MANAGER',
  RESTAURANT_MANAGER: 'RESTAURANT_MANAGER',
  HOST_RESERVATIONIST_ADVANCED: 'HOST_RESERVATIONIST_ADVANCED',
  HOST_RESERVATIONIST_STANDARD: 'HOST_RESERVATIONIST_STANDARD',
  IT_USER_ADMINISTRATOR: 'IT_USER_ADMINISTRATOR',
  PROMOTER: 'PROMOTER',
})

export const ShiftCategories = {
  BREAKFAST: 'Breakfast',
  BRUNCH: 'Brunch',
  LUNCH: 'Lunch',
  DAY: 'Day',
  DINNER: 'Dinner',
  NIGHT: 'Night',
  LEGACY: 'Night',
  UNCATEGORIZED: 'Uncategorized',
}

export const DayRanges = {
  YESTERDAY: 'YESTERDAY',
  L3DAY: 'L3DAY',
  L7DAY: 'L7DAY',
  L30DAY: 'L30DAY',
  THISMONTH: 'THISMONTH',
  LASTMONTH: 'LASTMONTH',
  THISYEAR: 'THISYEAR',
  LASTYEAR: 'LASTYEAR',
}

const ActualContactDestination = {
  CLIENT: 'CLIENT',
  SOURCE: 'SOURCE',
  SOURCE_AND_CLIENT: 'SOURCE_AND_CLIENT',
}

export const NotificationLevel = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const isMessageSentToClient = messageDestinationString =>
  messageDestinationString === ActualContactDestination.CLIENT ||
  messageDestinationString === ActualContactDestination.SOURCE_AND_CLIENT ||
  !messageDestinationString

export const isMessageSentToSource = messageDestinationString =>
  messageDestinationString === ActualContactDestination.SOURCE || messageDestinationString === ActualContactDestination.SOURCE_AND_CLIENT

export const getMessageDestinationString = (doSendToClient, doSendToSource) => {
  if (doSendToClient && doSendToSource) {
    return ActualContactDestination.SOURCE_AND_CLIENT
  }

  if (doSendToClient) {
    return ActualContactDestination.CLIENT
  }

  if (doSendToSource) {
    return ActualContactDestination.SOURCE
  }

  return null
}

// https://sevenrooms.atlassian.net/browse/SXI-806
export const TransactionTypes = {
  WEB: 'transaction_type_web',
  WEB_ORDER: 'transaction_type_web_order_charge',
  INTERNAL: 'transaction_type_internal',
  AUTO_INTERNAL: 'transaction_type_auto_internal',
  SUBSCRIPTION: 'transaction_type_subscription',
  REQUEST: 'transaction_type_request_info',
  REQUEST_REMOVED: 'transaction_type_request_removed',
  REQUEST_FULFILLED: 'transaction_type_request_fulfilled',
  REFUND: 'transaction_type_refund',
  AUTO_REFUND: 'transaction_type_auto_refund',
  SAVED: 'transaction_type_save',
  REMOVED: 'transaction_type_remove',

  REQUEST_REFUND: 'transaction_type_request_refund',
  THREE_D_SECURE_STARTED: 'transaction_type_3d_secure_started',
  THREE_D_SECURE_FAILED: 'transaction_type_3d_secure_failed',
  THREE_D_SECURE_ADD_CARD_STARTED: 'transaction_type_3d_secure_add_card_started',
  PAYMENT_STARTED: 'transaction_payment_started',
}

export const TransactionStatus = {
  SUCCEEDED: 'status_succeeded',
  FAILED: 'status_failed',
}

export const FONT_OPTIONS = [
  'Esteban',
  'Faustina',
  'Kameron',
  'Montserrat',
  'Open Sans Condensed',
  'PT Serif',
  'Palanquin',
  'Questrial',
  'Roboto',
]

export const ENVIRONMENTS = {
  PRODUCTION: 'PRODUCTION',
  DEVELOPMENT: 'DEVELOPMENT',
  QA: 'QA',
  DEMO: 'DEMO',
}
